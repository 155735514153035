import { EcoClient } from "@eco/client";
import { useEcoClient } from "@eco/react-lib";
import { useEffect, useState } from "react";

export type TCheckPasswordResult = { 
    userName : string|null,
    error?: Error|null | unknown,
    isLoading : boolean
    isChecked : boolean,
    csrf?: string | null
}

const useCheckPassword = (email:string|null, password:string|null) : TCheckPasswordResult => {

    const [result, setResult] = useState<TCheckPasswordResult>({
        userName : email,
        error: null,
        isLoading : false,
        isChecked: false,
        csrf: null,
    }) ;

    const ecoClient:EcoClient = useEcoClient() ;

    useEffect(() => {

        const checkPassword = async (user:string, password:string)  => {
            setResult((oldState) =>({
                ...oldState,
                isLoading: true
                })
            )
            try {
                const csrf =   await ecoClient.authenticate(user, password);
                setResult({
                    userName: email,
                    csrf,
                    isLoading: false,
                    isChecked: true
                    }
                )
            } catch(error){
                setResult({
                    userName : email,
                    error,
                    isLoading : false,
                    isChecked: false,
                })
            }
        };

        if(email && password) {
            checkPassword(email,password);
        }

        return;
    
    }, [email, password, ecoClient]);

    return result ; 

}

export default useCheckPassword ;