import ReactGA from 'react-ga4';
import { UaEventOptions } from "react-ga4/types/ga4";

/**
 * Centralize event hit sending function for Google Analytics 4
 *
 * @param eventOptionsOrName {string | UaEventOptions} event options if any or event name
 * @param eventParams {object} event parameters
 */
export const ga_Event = (
    eventOptionsOrName: string | UaEventOptions,
    eventParams?: any
): void => {
    ReactGA.event(eventOptionsOrName, eventParams);
};
