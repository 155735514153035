import { useEcoClient, useEcoMutation } from "@eco/react-lib"

export const useAzureOAuth2 = () => {

    const ecoClient = useEcoClient()
    const mutation = useEcoMutation<unknown, AzureOAuth2Mutation, AzureOAuth2Error>(({ email, accessToken }: AzureOAuth2Mutation) => {
        return ecoClient.azureOAuth2(email, accessToken)
    })

    return mutation
}

type AzureOAuth2Mutation = {
    email: string, 
    accessToken: string
}

type AzureOAuth2Error = {
    title: string, 
    status: number,
    /** JSON string for user's data from azure account */ 
    detail: string
}

