import { GenericFormType } from "@eco/react-lib";
import { useTranslation } from "react-i18next";

export const usePasswordFormSchema = (): GenericFormType => {
    const { t } = useTranslation()
    return {
		className: "sform",
		schema: {
			btnSubmit: {
				text: t("Login.PasswordForm.ButtonSubmit.label"),
				className: "btn-secondary w-100",
				id: "loginSubmitButtonPassword",
			},
			type: "object",
			required: ["password"],
			properties: {
				password: {
					type: "string",
					title: t("Login.PasswordForm.Password.Textfield.label"),
				},
			},
		},
		uiSchema: {
			password: {
				"ui:widget": "password",
				"ui:options": {
					grid: {
						xs: 12,
						mb: 3,
					},
				},
			},
		},
	};
}