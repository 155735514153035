import { makeStyles } from '@mui/styles';

/**
 * Override Material UI default primary style
 */
export const useStyles = makeStyles({
	form: {
		"& .MuiTextField-root, & .MuiButton-root": {
			width: "100%",
		},
		"& .MuiButton-root": {
			backgroundColor: "#FFA500",
		},
		"& .MuiButton-root:hover": {
			backgroundColor: "#FF9300",
		},
	},
});
