import React from 'react';
import { GenericForm } from '@eco/react-lib';
import { useResetPasswordSchema } from '../hooks/useResetPasswordSchema';
import { useResetPasswordHandler } from '../hooks/useResetPasswordHandler';

const ResetPasswordForm = () : JSX.Element => {
    const { handleFormSubmit, isLoading } = useResetPasswordHandler();
    const params = new URLSearchParams(window.location.search);
    const email = params.get('cn') || '';
    const registrationFormSchema = useResetPasswordSchema({
        email,
        replace: '',
        confirm: '' 
    });

    return (
        <>
            <GenericForm
                {...registrationFormSchema}
                defaultValue={{ email, replace: '', confirm: '' }}
                isSubmiting= {isLoading}
                onSubmit={ handleFormSubmit }
            />
        </>
    );
}

export default ResetPasswordForm;

