import { GenericFormType } from "@eco/react-lib";
import { useTranslation } from "react-i18next";
import { useResetPasswordValidate } from './useResetPasswordValidate';

export const useResetPasswordSchema = (defaultValue?: ResetPasswordType): GenericFormType<ResetPasswordType> => {
    const { t } = useTranslation();
    const validate = useResetPasswordValidate();
    return {
        className: "sform",
        schema: {
            btnSubmit: {
                text: t('Login.PasswordReset.ButtonSubmit.label'),
                className: 'btn-secondary w-100',
                id: 'loginSubmitButtonResetPassword'
            },
            type: 'object',
            required: ["replace", "confirm"],
            properties: {
                email: {
                    type: "string",
                    title: t('Login.PasswordReset.email.label')
                },
                replace: { 
                    type: 'string', 
                    title: t('Login.PasswordReset.replace.label'),
                    default: ''                    
                },
                confirm: { 
                    type: 'string', 
                    title: t('Login.PasswordReset.confirm.label'),
                },
            },
        },
        uiSchema: {
            email: {
                "ui:readonly": true,
                default: `${defaultValue?.email || ''}`
            },
            replace: {
                "ui:widget": "password",
                classNames: "lll",
                default: `${defaultValue?.replace || ''}`
            },
            confirm: {
                "ui:widget": "password",
                classNames: "lll",
                default: `${defaultValue?.confirm || ''}`
            }
        },
        validate: validate
    }
}

export type ResetPasswordType = {
    email: string,
    replace?: string;
    confirm?: string;
};