import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import './Layout.css';
import { useTranslation } from 'react-i18next';

const Layout: React.FC =  () => {
    const { t } = useTranslation();
    const location = useLocation();
    const isShowGreeting = location.pathname === '/register';

    return(
       <div className="scontainer">
            <section className="slayout">
                {isShowGreeting ? (
                    <>
                        <div className="registration-greeting left" style={{ backgroundImage: 'url("/assets/img/registration-greeting-left.png")' }}>
                            <label dangerouslySetInnerHTML={{__html: t('Login.Registration.Greeting.Left.label')}}></label>
                        </div>
                        <div className="registration-greeting right" style={{ backgroundImage: 'url("/assets/img/registration-greeting-right.png")' }}>
                            <label dangerouslySetInnerHTML={{__html: t('Login.Registration.Greeting.Right.label')}}></label>
                        </div>
                    </>
                ) : (<></>)}
                <div className="slayout-header">
                    <h1 className="stitle">
                        { t('Login.Layout.welcomeMessage.label') }
                        <img className="slogo-sds" src="/assets/img/logo-inv.png" alt="logo-sds-one-click"/>
                        <img className="slogo-eco" src="/assets/img/logo-ecomundo-inv.png" alt="logo-sds-one-click"/>
                    </h1>
                </div>
                <div className="slayout-body">
                    <Outlet />
                </div>
            </section>
       </div>
    )
}

export default Layout;
