import { FormValidation } from 'react-jsonschema-form';
import { ResetPasswordType } from './useResetPasswordSchema';
import { useTranslation } from 'react-i18next';

export const useResetPasswordValidate = () => {
    const {t} = useTranslation();
    
    return (formData: ResetPasswordType, errors: FormValidation) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%^&*()_+\-=\[\]{};\':\"\\|,.<>\/?])[A-Za-z\d!@#\$%^&*()_+\-=\[\]{};\':\"\\|,.<>\/?]{8,}/

        if (!formData.replace?.match(regex)) {
            errors.replace.addError(t('Login.PasswordReset.replace.validation.error'));
        }

        if (formData.confirm !== formData.replace) {
            errors.confirm.addError(t('Login.PasswordReset.confirm.notequals.error'));
        }

        return errors;
    };
}