import { GenericFormType } from "@eco/react-lib";
import { FormValidation } from "@rjsf/core";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { SelectCountry } from "../components/SelectCountry";

export const useRegistrationFormSchema = (defaultValue?: RegistrationType): GenericFormType => {

    const { t } = useTranslation()
    const lgCode = navigator.language.split('-')[1];
    
    return {
		className: "sform field2-s field3-s",
		schema: {
			btnSubmit: {
				id: "submitFormRegistrationButton",
				text: t(
					"Login.RegistationForm.CreateAnAccount.ButtonSubmit.label"
				),
			},
			type: "object",
			required: [
				"email",
				"password",
				"organization",
				"firstName",
				"lastName",
				"country",
			],
			properties: {
				email: {
					type: "string",
					title: t("Login.RegistationForm.TextField.Email.label"),
					default: `${
						defaultValue?.email ? defaultValue?.email : ""
					}`,
					pattern:
						process.env.REACT_APP_DISABLE_EMAIL_EXTENDS === "true"
							? "^\\s*[a-zA-Z0-9\\._-]+@[a-zA-Z0-9\\.-]+\\.[a-zA-Z]{2,4}\\s*|\\s*[a-zA-Z0-9\\._%+-]+@ecomundo\\.eu\\s*$" // Excluding aliases mail such as test+123@gmail.com
							: "^\\s*[a-zA-Z0-9\\._%+-]+@[a-zA-Z0-9\\.-]+\\.[a-zA-Z]{2,4}\\s*$",
				},
				lastName: {
					type: "string",
					title: t("Login.RegistationForm.TextField.Name.label"),
					default: `${
						defaultValue?.lastName ? defaultValue?.lastName : ""
					}`,
				},
				firstName: {
					type: "string",
					title: t("Login.RegistationForm.TextField.FirstName.label"),
					default: `${
						defaultValue?.firstName ? defaultValue?.firstName : ""
					}`,
				},
				country: {
					type: "string",
					title: t("Login.RegistationForm.TextField.Country.label"),
					default: `${lgCode}`,
				},
				organization: {
					type: "string",
					title: t("Login.RegistationForm.TextField.Company.label"),
					default: `${
						defaultValue?.organization
							? defaultValue?.organization
							: ""
					}`,
				},
				password: {
					type: "string",
					title: t("Login.RegistationForm.TextField.Password.label"),
					default: `${
						defaultValue?.password ? defaultValue?.password : ""
					}`,
				},
				promoCode: {
					type: "string",
					title: t("Login.RegistationForm.TextField.PromoCode.label"),
				},
				rgpdAccept: {
					type: "array",
					title: "",
				},
			},
		},
		uiSchema: {
			email: {
				"ui:options": {
					grid: {
						xs: 12,
					},
				},
			},
			password: {
				"ui:widget": "password",
				"ui:options": {
					inputType: "password",
					grid: {
						xs: 12,
					},
				},
				// classNames: "lll",
			},
			firstName: {
				"ui:widget": "text",
				"ui:options": {
					semantic: {
						fluid: true,
						inverted: false,
						size: "small",
					},
					grid: {
						xs: 6,
					},
				},
			},
			lastName: {
				classNames: "task-title foo-bar",
				"ui:options": {
					semantic: {
						fluid: true,
						inverted: false,
						size: "small",
					},
					grid: {
						xs: 6,
					},
				},
			},
			country: {
				"ui:field": "SelectCountry",
				"ui:options": {
					isNativeName: true,
				},
			},
			organization: {
				"ui:options": {
					grid: {
						xs: 12,
					},
				},
			},
			promoCode: {
				"ui:options": {
					grid: {
						xs: 12,
					},
				},
			},
			rgpdAccept: {
				"ui:field": "EcoCheckbox",
				"ui:options": {
					options: [
						{
							label: (
								<p
									dangerouslySetInnerHTML={{
										__html: t(
											"Login.RegistationForm.TextField.RGPD.label"
										)
											.replace(
												"$1",
												process.env.REACT_APP_CGV_URL ||
													""
											)
											.replace(
												"$2",
												process.env.REACT_APP_CGU_URL ||
													""
											),
									}}
								></p>
							),
							value: true,
						},
					],
					grid: {
						mb: 4
					}
				},
			},
		},
		fields: {
			SelectCountry: SelectCountry
		},
		transformErrors: (errors) =>
			errors.map((e) => {
				if (e.name === "pattern" && e.property === ".email") {
					e.message = t("Login.Email.Textfield.Validation.error");
				}
				if (e.name === "required") {
					e.message = t("Login.Form.Textfield.Required.error");
				}
				return e;
			}),
		validate: (formData: RegistrationType, errors: FormValidation) => {
			const regex =
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%^&*()_+\-=\[\]{};\':\"\\|,.<>\/?])[A-Za-z\d!@#\$%^&*()_+\-=\[\]{};\':\"\\|,.<>\/?]{8,}/;
			if (!formData.password?.match(regex)) {
				errors.password.addError(
					t(
						"Login.RegistationForm.TextField.Password.validation.error"
					)
				);
			}

			if (_.isEmpty(formData.rgpdAccept)) {
				errors.rgpdAccept.addError(
					t("Login.Form.Textfield.Required.error")
				);
			}

			return errors;
		},
	};
}

export type RegistrationType = {
    email?: string | any,
    lastName?: string,
    firstName?: string,
    organization?: string,
    password?: string,
    rgpdAccept?: Array<boolean>,
    promoCode?: string,
}
