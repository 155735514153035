/* eslint-disable @typescript-eslint/no-explicit-any */
import { EcoClient, AxiosError } from "@eco/client";
import { useNavigate } from "react-router-dom";
import { useEcoClient, useEcoMutation, useDisplayAlert } from "@eco/react-lib";
import { useTranslation } from "react-i18next";
interface IuseResetPassword {
    handleFormSubmit: (value?: Record<string, any>) => void, 
    isLoading: boolean
}

export const useResetPasswordHandler = () : IuseResetPassword => {
    const ecoClient:EcoClient = useEcoClient();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const displayAlert = useDisplayAlert();

    const mutation = useEcoMutation<Record<string, any>, ResetPassowrdInfo, AxiosError>(async (params: ResetPassowrdInfo) => {
        
        return ecoClient.post({
            path: `runtime/resetPassword.save?replace=${encodeURIComponent(params.replace)}&confirm=${encodeURIComponent(params.confirm)}&username=${encodeURIComponent(params.email)}`, 
            data: {},
            dataType: 'json',
        });
    })

    const handleFormSubmit = (value?: Record<string, any | any>) => {
        mutation.mutate(value as ResetPassowrdInfo, {
            onSuccess: (data) => {
                if(data?.passwordChanged) {
                    displayAlert({
                        id: `reset-password-success`,
                        label: t('Login.PasswordReset.success.text'),
                        AlertProps: {
                            severity: 'success',
                        },
                    });
                    navigate('/email');
                } else if (data?.reasonKey) {
                    displayAlert({
                        id: `reset-password-success`,
                        label: data?.reasonKey || t('Login.PasswordReset.error.text'),
                        AlertProps: {
                            severity: 'error',
                        },
                    });
                }
            },
            onError: () => {
                displayAlert({
                    id: `reset-password-error`,
                    label: t('Login.PasswordReset.error.text'),
                    AlertProps: {
                        severity: 'error',
                    },
                })
            }
        });
    };

    return {
        handleFormSubmit,
        isLoading: mutation.isLoading
    };
};

export type ResetPassowrdInfo = {
    email: string,
    replace: string,
    confirm: string
}