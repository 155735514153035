// eslint-disable-next-line @typescript-eslint/no-explicit-any
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import useCheckEmail from './useCheckEmail';
import ReactGA from 'react-ga4';

const useEmailFormHandler = (): {
	handleFormSubmit: (value?: Record<string, any>) => void;
	isLoading: boolean;
} => {
	const [userEmail, setUserEmail] = useState<string | null>(null);

	const navigate = useNavigate();

	const { userName, isChecked, isLoading, status } = useCheckEmail(userEmail);

	const handleFormSubmit = (value?: Record<string, any>) => {
		const email = value?.email.trim();
		setUserEmail(email);
		// ga_EmailFormConfirm();
		sessionStorage.setItem("email", email);
	};

	useEffect(() => {
		if (status === 200) {
			ReactGA.event("click", {
                link_domain: "/password",
                link_id: "loginSubmitButtonEmail",
                link_url: "/password",
                outbound: false,
			});
			
			navigate("/password", { state: { useName: userName } });
		}

		if (status === 404) {
			ReactGA.event("click", {
                link_domain: "/register",
                link_id: "loginSubmitButtonEmail",
                link_url: "/register",
                outbound: false,
            });
			
			navigate("/register");
		}
	}, [status, isChecked, navigate]);

	return {
		handleFormSubmit,
		isLoading,
	};
};

/* const ga_EmailFormConfirm = () => { 
    ReactGA.event('email_form_confirm', { 
        category: 'Event', 
        action: 'Auth', 
        label: 'Event/Registration/EmailRegistrationOrConnexion', }); 
    } */

export default useEmailFormHandler;