import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NotFound: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className="stext-center">
            <p>{t("Login.PageNotFound.label")}</p>
            <p style={{ marginTop: "50px" }}>
                <Link to="/email">
                    {t("Login.PageNotFound.BackToLogin.label")}
                </Link>
            </p>
        </div>
    );
};

export default NotFound;
