import { useDisplayAlert, useGlobalValue } from "@eco/react-lib"
import { gapi, loadAuth2 } from "gapi-script"
import { createRef, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useRecoilState } from "recoil"
import { useGoogleOAuth2 } from "shares/hooks/useGoogleOAuth2"
import { useRedirectToApp } from "shares/hooks/useRedirectToApp"
import { registrationAtom } from "state/atom"
import { ga_Event } from "shares/utils/ga"

export const GoogleOAuth2 = () => {

    const googleOAuthMutation = useGoogleOAuth2()
    const buttonRef = createRef<HTMLButtonElement>()
    const googleClientId = useMemo(() => process.env.REACT_APP_GOOGLE_CLIENT_ID, [])
    const redirect = useRedirectToApp()
    const displayAlert = useDisplayAlert()
    const { t } = useTranslation()

    useEffect(() => {
        initGAPI()
    }, [])

    useEffect(() => {
        if (googleOAuthMutation.isSuccess) {
            redirect()
        }
    }, [googleOAuthMutation.isSuccess])

    useEffect(() => {
        if (googleOAuthMutation.error) {
            const { detail } = googleOAuthMutation.error
            if (!detail) {
                return displayAlert({
                    label: t('Login.Common.Error.message'),
                    severity: "error",
                    autoHideDuration: 5000,
                })
            }
        }
    }, [googleOAuthMutation.error])

    const handleGoogleSigninFailure = (response: any) => {
        console.log('[OAuth] Google Signin Failed')
        console.log(response)
        ga_Event({
            category: "Event",
            action: "Auth",
            label: "Event/Auth/GoogleAuthFailure",
        });
    }

    const loadAuth2 = useCallback(() => {
        return new Promise((resolve, reject) => {
            gapi.load('client:auth2', () => {
                gapi.client
                    .init({
                        clientId: googleClientId,
                        scope: 'https://www.googleapis.com/auth/userinfo.email',
                    })
                    .then(() => {
                        const auth = gapi.auth2.getAuthInstance();
                        resolve(auth)
                    })
                    .catch(() => reject())
            });
        })
    }, [])

    const initGAPI = useCallback(async () => {
        try {
            await loadAuth2()
        } catch (e) {
            console.log('[GAPI] init failed', e)

        }
    }, [gapi])

    const handleClick = useCallback(async () => {
        const auth = await gapi.auth2.getAuthInstance();
        await auth.signIn();
        const { access_token } = await auth.currentUser.get().getAuthResponse();
        googleOAuthMutation.mutate({
            id_token: access_token,
            email: (undefined) as any
        })
        ga_Event({
            category: "Event",
            action: "Auth",
            label: "Event/Auth/GoogleAuthSuccess",
        });
        ga_Event("login", {
            method: "Google",
        });
    }, [])

    return (
        <button
            ref={buttonRef}
            className="sbtn-icon"
            onClick={handleClick}>
            <img src="/assets/img/google.png" alt="google" />
        </button>
    )
}
