import React from 'react';

const Maintenance: React.FC = () => {

    return (
        <>            
            <p className='text-center m-top-50'>
                SDS1Click website is down for maintenance. <br/>
                It will be back soon.<br/>
                Sorry for the inconvenience.
            </p>
                    
            <p className='text-center m-top-50'>
                Le site SDS1Click est actuellement en maintance.<br/>
                Excusez-nous pour la gêne occasionnée.<br/>
                Merci de votre compréhension.
            </p>
        </>
    );
}

export default Maintenance;

