import { useEcoClient, useEcoMutation } from "@eco/react-lib"

export const useGoogleOAuth2 = () => {

    const ecoClient = useEcoClient()
    const mutation = useEcoMutation<unknown, GoogleOAuth2Mutation, GoogleOAuth2Error>(({ email, id_token }: GoogleOAuth2Mutation) => {
        return ecoClient.googleOAuth2(id_token)
    })

    return mutation
}

type GoogleOAuth2Mutation = {
    email: string, 
    id_token: string
}

type GoogleOAuth2Error = {
    title: string, 
    status: number,
    /** JSON string for user's data from google account */ 
    detail: string
}
