import { EcoClient } from "@eco/client";
import { useLocation } from 'react-router-dom';
import { useEcoClient } from "@eco/react-lib";
import { useCallback, useEffect, useState } from "react";
import { IUser } from "./useRegistrationHandler";

export type TSendRegistrationResult = { 
    user : {
        userEmail?: string|null,
        organisation?: string|null
    },
    error?: Error|null,
    status?: number,
    isLoading : boolean
    isSucceded : boolean,
    errorMessage?: string,
    setIsClicked?: (val: boolean) => void
}


const useSendRegistration = (user:IUser) : TSendRegistrationResult => {

    const [result, setResult] = useState<TSendRegistrationResult>({
        user : {},
        error: null,
        isLoading : false,
        isSucceded: false,
        errorMessage: undefined
    }) ;
    const [isClicked, setIsClicked] = useState(false)
    const ecoClient:EcoClient = useEcoClient();
    const urlParams = new URLSearchParams(useLocation().search);

    const sendRegistration = useCallback(async (user:IUser)  => {
        setIsClicked(false)
        try {
            setResult((oldState) =>({
                ...oldState,
                isLoading: true
                })
            )
            const {data}  = await ecoClient.post<any>({path:'registration.new', 
                data: {
                    ...user, 
                    loginUrl: process.env.REACT_APP_REDIRECT_ON_LOGIN,
                    failUrl: process.env.REACT_APP_REDIRECT_ON_REGISTER,
                    sponsorId: urlParams.get('sponsorId')
                }, dataType: "JSON" });
            setResult({
                user: {
                    userEmail: user.email,
                    organisation: user.organization
                },
                status: data && data.status ? data.status : 202,
                isLoading: false,
                isSucceded:  !data ? true : false,
                }
            );
        } catch(error: any) {
            const {response} = error;
            setResult((oldState) =>({
                ...oldState,
                isLoading: false,
                error,
                status: response && response.status,
                errorMessage: response && (response.data.fieldErrors || response.data.reason || response.data.detail)
                })
            )
        }
    }, [ecoClient]);

    useEffect(() => {
        if(Object.keys(user).length != 0 && !result.isLoading && isClicked) {
            sendRegistration(user)
        }
    }, [user, result, isClicked]);
    
    return {...result, setIsClicked} ; 

}


export default useSendRegistration ;