import { Autocomplete, FormControl, MenuItem, TextField } from "@mui/material";
import { FieldProps } from "@rjsf/core";
import { useSelectCountry } from "../hooks/useSelectCountry";

/**
 * TODO: Refactor SelectCountry from @eco/react-lib for use with login + sds-one-click apps
 * 
 * @param props 
 * @returns 
 */
export const SelectCountry = (props: FieldProps) => {
    const { title: label } = props.schema;

    const { options, selected, handleChange } = useSelectCountry(props);

    return (
        <FormControl
            id={props.idSchema.$id}
            className={`control-select-language ${props.className}`}
            fullWidth
        >
            <Autocomplete
                options={options}
                autoHighlight
                value={selected || { code: "", name: "" }}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                    option.code === value.code && option.name === value.name
                }
                onChange={handleChange}
                loading={options?.length === 0}
                renderOption={(props, option) => (
                    <MenuItem value={option.code} {...props}>
                        {renderFlag(option.code, option.flag)} {option.name}
                    </MenuItem>
                )}
                renderInput={(params) => {
                    const option = options?.find(
                        (item) =>
                            item.name ===
                            ((params.inputProps as Record<string, unknown>)
                                .value as string)
                    );

                    return (
                        <div className="control-select-language-selected">
                            <TextField
                                {...params}
                                label={label}
                                required={props.required}
                                inputProps={{
                                    ...(params.inputProps as any),
                                    autoComplete: "off",
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment:
                                        option &&
                                        renderFlag(option.code, option.flag),
                                }}
                            />
                        </div>
                    );
                }}
            />
        </FormControl>
    );
};

const renderFlag = (code: string, flag?: string) => {
    return (
        <img
            className="float-left mr-10"
            height="25px"
            src={flag}
            alt={code}
            style={{ marginRight: "6px" }}
        />
    );
};
