import { GenericForm } from "@eco/react-lib";
import React from "react";
import FederateLogin from "shares/components/federate-login/FederateLogin";
import useEmailFormHandler from "../hooks/useEmailFormHandler";
import { useEmailFormSchema } from "../hooks/useEmailFormSchema";
import { useTranslation } from "react-i18next";
import { useGlobalValue } from "@eco/react-lib";
import { accountValidated } from "state/atom";
import { Box } from "@mui/material";
import { useStyles } from "../../../shares/hooks/useStyles";

const EmailForm: React.FC = () => {
    const { handleFormSubmit, isLoading } = useEmailFormHandler();

    const { t } = useTranslation();
    const emailFormSchema = useEmailFormSchema();
    const registered = useGlobalValue(accountValidated);

    const classes = useStyles();

    return (
        <>
            <GenericForm
                {...emailFormSchema}
                className={`sform ${classes.form}`}
                defaultValue={{ email: sessionStorage.getItem("email") || "" }}
                isLoading={isLoading}
                onSubmit={handleFormSubmit}
            />

            {!registered && (
                <Box sx={{ textAlign: "center" }}>
                    <p
                        className="text-center m-top-30"
                        style={{ fontSize: "0.9em" }}
                    >
                        {t("Login.Email.text1")}
                    </p>

                    <p
                        className="text-center m-top-10"
                        style={{ fontSize: "0.9em" }}
                    >
                        {t("Login.Email.text2")}
                    </p>
                </Box>
            )}

            {registered && (
                <Box sx={{ textAlign: "center" }}>
                    <p
                        className="text-center m-top-30"
                        style={{ fontSize: "0.9em" }}
                    >
                        {t("Login.Email.Account.Validated.message")}
                    </p>
                </Box>
            )}

            <FederateLogin />
        </>
    );
};

export default EmailForm;
