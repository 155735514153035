import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonSubmit, GenericForm } from "@eco/react-lib";
import FederateLogin from "shares/components/federate-login/FederateLogin";
import usePasswordFormHandler from "../hooks/usePasswordHandler";
import { usePasswordFormSchema } from "../hooks/usePasswordFormSchema";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../shares/hooks/useStyles";

const PasswordForm: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    useEffect(() => {
        if (!sessionStorage.getItem("email")) {
            navigate("/email");
        }
    }, [navigate]);

    const {
        handlePasswordReset,
        handleFormSubmit,
        isLoading,
        showError,
        isSendMailLoading,
    } = usePasswordFormHandler();

    const passwordFormSchema = usePasswordFormSchema();

    const classes = useStyles();

    return (
        <>
            <GenericForm
                {...passwordFormSchema}
                className={`sform ${classes.form}`}
                defaultValue={{ password: "" }}
                extraErrors={
                    showError ? {
                        password: {
                            __errors: [t("Login.PasswordForm.Password.error")],
                        },
                    } : {}
                }
                isLoading={isLoading}
                onSubmit={handleFormSubmit}
            />
            <ButtonSubmit
                variant="text"
                color="info"
                className="btn-reset-password"
                label={t("Login.PasswordReset.link")}
                isSubmit={isSendMailLoading}
                onClick={handlePasswordReset}
            />
            <FederateLogin />
        </>
    );
};
export default PasswordForm;
