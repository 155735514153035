import { createTheme } from '@material-ui/core/styles';
import {DefaultTheme, EcoRoot, ecoSecondaryColor, StaticRouteMappings, staticRouteMappingsState, TranslationMap, EcoInitialState } from "@eco/react-lib" ;
import Layout from 'shares/components/layout/Layout';
import EmailForm from './pages/email/containers/EmailForm';
import PasswordForm from './pages/password/containers/PasswordForm';
import ResetPasswordForm from './pages/resetpassword/containers/ResetPasswordForm';
import Registration from './pages/registration/containers/RegistrationForm';
import NotFound from './pages/not-found/NotFound';
import './app.css';
import RegistrationConfirm from 'pages/registration/containers/RegistrationConfirm';
import { RegistrationValidated } from 'pages/registration/containers/RegistrationValidated';
import Maintenance from 'pages/maintenance/Maintenance';

function App():  JSX.Element {

    // custom state
    const initialState = initialAppState() ;

    // custom theme
    //const theme = customTheme()  ;
    
    return (
        <EcoRoot initialState={initialState} defaultRoute="/email" /*theme={theme}*/ />
    );
}



/**
 * Build initial recoil app state.
 * 
 * @returns initial application state
 */
const initialAppState = () : EcoInitialState[] => {

    // static routes
    const staticRoutes: StaticRouteMappings = {
        Layout: <Layout />,
        EmailForm: <EmailForm />,
        PasswordForm: <PasswordForm />,
        ResetPasswordForm: <ResetPasswordForm />,
        Registration: <Registration />,
        RegistrationConfirm: <RegistrationConfirm/>,
        RegistrationValidated: <RegistrationValidated/>,
        Maintenance: <Maintenance />,
        NotFound : <NotFound />
    }
    
    // No translations from backend > i18n defined in public/i18n 
    const translations = new TranslationMap([]) ;
    translations.setLoaded() ; 
    
    return [
        {
            state : staticRouteMappingsState,
            value : staticRoutes
        }
    ] ;


/*
const customTheme = () => {
    return createTheme({
        ...DefaultTheme,
        components: {
            ...DefaultTheme.components,
            MuiButton: {
                // defaultProps: {
                //     variant: 'standard'
                // },
                styleOverrides: {
                    root: {
                        backgroundColor: ecoSecondaryColor,
                        fontSize: '14px',
                        fontWeight: 300,
                        textTransform: 'none',
                        textAlign: 'center',
                        maxWidth: '100%',
                        width: '100%',
                        borderRadius: 0,
                        margin: 'auto'
                    },
                }
            },
        }
    }) ;
}*/

}

export default App;
