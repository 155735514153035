import { EcoClient, AxiosError } from "@eco/client";
import { useEcoClient, useEcoMutation, useDisplayAlert } from "@eco/react-lib";
import { useEffect, useState } from 'react';
import { useRedirectToApp } from 'shares/hooks/useRedirectToApp';
import useCheckPassword from './useCheckPassword';
import { useTranslation } from "react-i18next";
import ReactGA from 'react-ga4';

export type PasswordResetInfo = {
    email: string,
    redirect: string,
    isSendMailLoading: boolean
};

const usePasswordFormHandler = (): {
	handlePasswordReset: (value?: Record<string, any | any>) => void;
	handleFormSubmit: (value?: Record<string, any>) => void;
	showError: boolean;
	isLoading: boolean;
	isSendMailLoading: boolean;
} => {
	const ecoClient: EcoClient = useEcoClient();
	const [userUserPasswotd, setUserPassword] = useState<string | null>(null);
	const [showError, setShowError] = useState<boolean>(false);
	const { error, csrf, isLoading } = useCheckPassword(
		sessionStorage.getItem("email"),
		userUserPasswotd
	);
	const redirect = useRedirectToApp();
	const displayAlert = useDisplayAlert();
	const { t } = useTranslation();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleFormSubmit = (value?: Record<string, any>) => {
		const password = value?.password;
		setShowError(false);
		setUserPassword(password);
		ReactGA.event("login", {method: 'Form'});
	};

	const mutation = useEcoMutation<
		Record<string, any>,
		PasswordResetInfo,
		AxiosError
	>(async (params: PasswordResetInfo) => {
		const email = sessionStorage.getItem("email");
		const { csrfToken } = await ecoClient.initialToken();

		return ecoClient.post({
			path: `runtime/resetPassword.update?email=${encodeURIComponent(
				email as string
			)}&redirect=${process.env.REACT_APP_REDIRECT_PASSWORD_RESET_URL}`,
			data: {},
			dataType: "json",
			config: {
				headers: {
					"x-csrf-Token": csrfToken,
				},
			},
		});
	});

	const handlePasswordReset = (value?: Record<string, any | any>) => {
		mutation.mutate(value as PasswordResetInfo, {
			onSuccess: (data) => {
				displayAlert({
					id: `reset-password-success`,
					label: t("Login.PasswordReset.message"),
					AlertProps: {
						severity: "success",
					},
				});
			},
			onError: () => {
				displayAlert({
					id: `reset-password-success`,
					label: t("Login.PasswordReset.error.message"),
					AlertProps: {
						severity: "error",
					},
				});
			},
		});
	};

	useEffect(() => {
		if (!error && csrf) {
			redirect();
		} else if (error) {
			setShowError(true);
		}
	}, [csrf, error]);

	return {
		handlePasswordReset,
		handleFormSubmit,
		showError,
		isLoading,
		isSendMailLoading: mutation.isLoading,
	};
};

export default usePasswordFormHandler;