import { LangPropType } from "@eco/react-lib";
import { FieldProps } from "@rjsf/core";
import { useCallback, useEffect, useState } from "react";
import { getOptions } from "../utils/getOptions";

export const useSelectCountry = (props: FieldProps) => {
    const [value, setValue] = useState<string>(props.formData);
    const [options, setOptions] = useState<Array<LangPropType>>([]);
    const [selected, setSelected] = useState<LangPropType>();

    useEffect(() => {
        setOptions(getOptions());
    }, [props.formData]);

    useEffect(() => {
        const option = findOption(props.formData);
        if (option || props.formData === null) {
            setValue(option?.code || "");
        }
    }, [options, props.formData]);

    useEffect(() => {
        setSelected(findOption(value));
    }, [value, options]);

    useEffect(() => {
        props.onChange?.(value === "" ? undefined : value);
    }, [value]);

    const findOption = useCallback(
        (code?: string) => {
            return options?.find(
                (item) => item.code.toUpperCase() === code?.toUpperCase()
            );
        },
        [options]
    );

    const handleChange = (
        e: React.SyntheticEvent<Element, Event>,
        value: LangPropType | null
    ) => {
        setValue(value?.code || "");
    };

    return {
        options,
        selected,
        handleChange,
    };
};
