import { atom } from "recoil";
import { RegistrationType } from "pages/registration/hooks/useRegistrationFormSchema";
import { TSendRegistrationResult } from '../pages/registration/hooks/useSendRegistration';

export type Email = {
    email: string
}

export const emailAtom = atom<Email>({
    key: 'userEmail',
    default: {
        email: ''
    } as Email
});

export const userRegistered = atom<TSendRegistrationResult['user']>({
    key: 'userRegistred',
    default: {
        userEmail: '',
        organisation: ''
    }
})

export const registrationAtom = atom<RegistrationType | null>({
    key: 'registration',
    default: null
});

export const accountValidated = atom<boolean>({
    key: 'accountValidated',
    default: false
});

