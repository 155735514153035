import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { AzureOAuth, SnackbarItem, useDisplayAlert, useGlobalValue } from '@eco/react-lib';
import './federateLogin.css';
import { useAzureOAuth2 } from 'shares/hooks/useAzureOAuth2';
import { useRedirectToApp } from 'shares/hooks/useRedirectToApp';
import { accountValidated, registrationAtom } from 'state/atom';
import { ga_Event } from 'shares/utils/ga';
import { gapi } from 'gapi-script'
import { GoogleOAuth2 } from './GoogleOAuth2';


const FederateLogin: React.FC = () => {
    const { t } = useTranslation()
    const azureOAuthMutation = useAzureOAuth2()
    const redirect = useRedirectToApp()
    const displayAlert = useDisplayAlert()
    const [register, setRegister] = useRecoilState(registrationAtom)
    const navigate = useNavigate()
    const registered = useGlobalValue(accountValidated);

    useEffect(() => {
        if (azureOAuthMutation.isSuccess) {
            redirect()
        }
    }, [azureOAuthMutation.isSuccess])

    useEffect(() => {
        register && navigate('/register')
    }, [register])


    useEffect(() => {
        if (azureOAuthMutation.error) {
            const { detail } = azureOAuthMutation.error
            if (!detail) {
                return displayError()
            }
            const profileObj = JSON.parse(detail)
            setRegister({
                email: profileObj.email,
                lastName: profileObj.familyName,
                firstName: profileObj.givenName,
            })
        }
    }, [azureOAuthMutation.error])


    const azureClientId = useMemo(() => process.env.REACT_APP_AZURE_CLIENT_ID, [])

    const federationLoginError: SnackbarItem = useMemo(() => {
        return {
            label: t('Login.Common.Error.message'),
            severity: "error",
            autoHideDuration: 5000,
        }
    }, [])

    const displayError = useCallback(() => {
        displayAlert(federationLoginError)
    }, [])

    const handleAzureSigninSuccess = (response: any) => {
        const { accessToken, account } = response
        azureOAuthMutation.mutate({
            accessToken,
            email: account.username
        })
        ga_Event({
            category: "Event",
            action: "Auth",
            label: "Event/Auth/MicrosoftAuthSuccess",
        });
        ga_Event("login", {
            method: "Microsoft",
        });
    }

    const handleAzureSigninFailure = (e: any) => {
        console.log('[OAuth] Azure Signin Failed')
        console.log(e)
        ga_Event({
            category: "Event",
            action: "Auth",
            label: "Event/Auth/MicrosoftAuthFailure",
        });
    }

    return (
        <section className="sother-login">
            <div className="sseparator"><span>{t('Login.FederateLogin.or.label')}</span></div>
            <p>{registered ? t('Login.FederateLogin.Account.Validated.message') : t('Login.FederateLogin.message')}</p>
            <div className="sflex sflex-center">

                {
                    azureClientId &&
                    <AzureOAuth
                        loginRequest={{ scopes: ['User.Read'] }}
                        onSucess={handleAzureSigninSuccess}
                        onError={handleAzureSigninFailure}
                        onClick={() => ga_Event({
                            category: "Button",
                            action: "Auth",
                            label: "Event/Auth/MicrosoftAuthButtonPressed",
                        })}
                    />
                }

                <GoogleOAuth2 />

            </div>
        </section>
    );
}



export default FederateLogin;