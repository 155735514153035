export const getCountriesList = (): Country[] => {
    return [
        {
            code: "as",
            displayName: "Asia",
        },
        {
            code: "at",
            displayName: "Austria",
        },
        {
            code: "be",
            displayName: "Belgium",
        },
        {
            code: "bg",
            displayName: "Bulgaria",
        },
        {
            code: "cz",
            displayName: "CzechRepublic",
        },
        {
            code: "hr",
            displayName: "Croatia",
        },
        {
            code: "cy",
            displayName: "Cyprus",
        },
        {
            code: "dk",
            displayName: "Denmark",
        },
        {
            code: "gb",
            displayName: "UnitedKingdom",
        },
        {
            code: "fr",
            displayName: "France",
        },
        {
            code: "de",
            displayName: "language_de",
        },
        {
            code: "ee",
            displayName: "Estonia",
        },
        {
            code: "fi",
            displayName: "Finland",
        },
        {
            code: "gr",
            displayName: "Greece",
        },
        {
            code: "hu",
            displayName: "Hungary",
        },
        {
            code: "ie",
            displayName: "Ireland",
        },
        {
            code: "is",
            displayName: "Iceland",
        },
        {
            code: "it",
            displayName: "Italy",
        },
        {
            code: "lv",
            displayName: "Latvia",
        },
        {
            code: "lt",
            displayName: "Lithuania",
        },
        {
            code: "li",
            displayName: "Liechtenstein",
        },
        {
            code: "lu",
            displayName: "Luxembourg",
        },
        {
            code: "mt",
            displayName: "Malta",
        },
        {
            code: "nl",
            displayName: "Netherlands",
        },
        {
            code: "no",
            displayName: "Norway",
        },
        {
            code: "na",
            displayName: "North America",
        },
        {
            code: "pl",
            displayName: "Poland",
        },
        {
            code: "pt",
            displayName: "Portugal",
        },
        {
            code: "ro",
            displayName: "Romania",
        },
        {
            code: "ru",
            displayName: "Russia",
        },
        {
            code: "ch",
            displayName: "Switzerland",
        },
        {
            code: "sk",
            displayName: "Slovakia",
        },
        {
            code: "si",
            displayName: "Slovenia",
        },
        {
            code: "es",
            displayName: "Spain",
        },
        {
            code: "se",
            displayName: "Sweden",
        },
        {
            code: "ua",
            displayName: "Ukraine",
        },
        /* {
            code: "eu",
            displayName: "EuropeanUnion",
        },
        {
            code: "home",
            displayName: "Home",
        },
        {
            code: "world",
            displayName: "World",
        }, */
        {
            code: "ca",
            displayName: "Canada",
        },
        {
            code: "us",
            displayName: "UnitedStates",
        },
        {
            code: "cn",
            displayName: "China",
        },
        {
            code: "jp",
            displayName: "Japan",
        },
        {
            code: "vn",
            displayName: "VietNam",
        },
        {
            code: "eg",
            displayName: "Egypt",
        },
        {
            code: "hk",
            displayName: "HongKong",
        },
        {
            code: "kr",
            displayName: "SouthKorea",
        },
        {
            code: "my",
            displayName: "Malaysia",
        },
        {
            code: "sg",
            displayName: "Singapore",
        },
        {
            code: "tw",
            displayName: "Taiwan",
        },
        {
            code: "lb",
            displayName: "Lebanon",
        },
        {
            code: "ma",
            displayName: "Morocco",
        },
        {
            code: "qa",
            displayName: "Qatar",
        },
        {
            code: "ar",
            displayName: "Argentina",
        },
        {
            code: "br",
            displayName: "Brazil",
        },
        {
            code: "nz",
            displayName: "NewZealand",
        },
        {
            code: "il",
            displayName: "Israel",
        },
        {
            code: "mx",
            displayName: "Mexico",
        },
        {
            code: "bo",
            displayName: "Bolivia",
        },
        {
            code: "co",
            displayName: "Colombia",
        },
        {
            code: "cg",
            displayName: "Republic of the Congo",
        },
        {
            code: "gm",
            displayName: "Gambia",
        },
        {
            code: "gt",
            displayName: "Guatemala",
        },
        {
            code: "kg",
            displayName: "Kyrgyzstan",
        },
        {
            code: "mg",
            displayName: "Madagascar",
        },
        {
            code: "ng",
            displayName: "Nigeria",
        },
        {
            code: "py",
            displayName: "Paraguay",
        },
        {
            code: "pe",
            displayName: "Peru",
        },
        {
            code: "sn",
            displayName: "Sénégal",
        },
        {
            code: "za",
            displayName: "South Africa",
        },
        {
            code: "uy",
            displayName: "Uruguay",
        },
        {
            code: "ec",
            displayName: "Ecuador",
        },
        {
            code: "mu",
            displayName: "Mauritius",
        },
        {
            code: "zm",
            displayName: "Zambia",
        },
        {
            code: "cl",
            displayName: "Chile",
        },
        {
            code: "TH",
            displayName: "Thailand",
        },
        {
            code: "PH",
            displayName: "Philippines",
        },
        {
            code: "ID",
            displayName: "Indonesia",
        },
        {
            code: "AU",
            displayName: "Australia",
        },
        {
            code: "AE",
            displayName: "United Arab Emirates",
        },
        {
            code: "KW",
            displayName: "Kuwait",
        },
        {
            code: "TR",
            displayName: "Turkey",
        },
        {
            code: "IN",
            displayName: "India",
        },
    ];
};

type Country = {
    code: string;
    displayName: string;
};
