import { GenericFormType, useGlobalValue } from "@eco/react-lib";
import { useTranslation } from "react-i18next";
// import ReactGA from 'react-ga4';
import { accountValidated } from 'state/atom';

export const useEmailFormSchema = (): GenericFormType => {
    const { t } = useTranslation();
    const registered = useGlobalValue(accountValidated);

    return {
		className: "sform",
		schema: {
			btnSubmit: {
				text: registered
					? t("Login.Account.Validated.Button.label")
					: t("Login.Email.Button.label"),
				className: "btn-secondary w-100",
				id: "loginSubmitButtonEmail",
				// ga_RegularAuthButton,
			},
			type: "object",
			required: ["email"],
			properties: {
				email: {
					type: "string",
					title: t("Login.Email.Textfield.label"),
					pattern:
						process.env.REACT_APP_DISABLE_EMAIL_EXTENDS === "true"
							? "^\\s*[a-zA-Z0-9\\._-]+@[a-zA-Z0-9\\.-]+\\.[a-zA-Z]{2,4}\\s*|\\s*[a-zA-Z0-9\\._%+-]+@ecomundo\\.eu\\s*$" // Excluding aliases mail such as test+123@gmail.com except for ecomundo
							: "^\\s*[a-zA-Z0-9\\._%+-]+@[a-zA-Z0-9\\.-]+\\.[a-zA-Z]{2,4}\\s*$",
				},
			},
		},
		uiSchema: {
			email: {
				"ui:options": {
					grid: {
						xs: 12,
						mb: 3,
					},
				},
			}
		},
		transformErrors: (errors) =>
			errors.map((e) => {
				if (e.name === "pattern" && e.property === ".email") {
					e.message = t("Login.Email.Textfield.Validation.error");
				}
				return e;
			}),
	};
}

/* const ga_RegularAuthButton = () => {
    ReactGA.event('regular_auth_btn', {
      category: 'Button',
      action: 'Auth',
      label: 'Event/Auth/AuthButtonPressed',
    });
  } */