import { EcoClient } from "@eco/client";
import { useEcoClient } from "@eco/react-lib";
import { useEffect, useState } from "react";

export type TCheckMailResult = { 
    userName : string|undefined,
    status?: number,
    isLoading : boolean
    isChecked : boolean
}

const useCheckEmail = (email:string|null) : TCheckMailResult => {

    const [result, setResult] = useState<TCheckMailResult>({
        userName : undefined,
        status: undefined,
        isLoading : false,
        isChecked: false
    }) ;

    const ecoClient:EcoClient = useEcoClient();

    const checkMail = async (email: string) => {
        try {
            
            setResult(oldState => ({...oldState, isLoading : true})) ;

            const res =   await ecoClient.checkMail(email);

            setResult({
                userName: email,
                status: res.status,
                isLoading: false,
                isChecked: true
            });
        } catch (e) {
            setResult((oldState) =>({
                ...oldState,
                isLoading: false,
                isChecked: false
            }));
        }
    }

    useEffect(() => {
        if(!email){
            return ;
        }

        checkMail(email) ;
    
    }, [email]);

    return result ; 

}


export default useCheckEmail ;