import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { userRegistered } from 'state/atom';
import { ga_Event } from 'shares/utils/ga';

const RegistrationConfirm: React.FC = ()  => {
    const { t } = useTranslation();
    const [user, setUser] = useRecoilState(userRegistered);

    ga_Event("confirm_sign_up", {
        category: "Event",
        action: "Auth",
        label: "Event/Registration/RegistrationSuccess",
    });

    useEffect(() => {
        return () => {
            setUser({userEmail: '', organisation: ''})
        }
    }, [])

    return (
        <>
            {
                user.userEmail != '' && 
                <div className="stext-center">
                    <h2 className="scolor-primary">
                        <strong>{ t('Login.RegistationConfirm.title') }</strong>
                    </h2>
                    <img className="eco-img" src="/assets/img/5Ableu.png" alt="picture" />
                    <p>{ t('Login.RegistationConfirm.message') }  
                        <br/>
                            <strong> {user?.userEmail} </strong><br/>
                        <br/>
                            <strong>{ t('Login.RegistationConfirm.didNotReceiveEmail.question') }</strong> 
                        <br/>
                            { t('Login.RegistationConfirm.CheckYourSpamBox.message') }
                        <br/>
                            {/* t('Login.RegistationConfirm.or.label') */}
                        <span className="scolor-primary"><strong>{/* t('Login.RegistationConfirm.ReSendEmail.label') */}</strong></span>
                    </p>
                </div>
            }
        </>
    )
}

export default RegistrationConfirm;