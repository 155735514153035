import React, { useEffect } from "react";
import useRegistrationHandler from "../hooks/useRegistrationHandler";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { GenericForm } from "@eco/react-lib";
import { useRegistrationFormSchema } from "../hooks/useRegistrationFormSchema";
import "../../../shares/components/form/form.css";
import { useTranslation } from "react-i18next";
import { registrationAtom, userRegistered } from "state/atom";
import { useStyles } from "../../../shares/hooks/useStyles";
import { ga_Event } from "shares/utils/ga";

const RegistrationForm: React.FC = () => {
    const { isSucceded, handleFormSubmit, user, errorMessage, isLoading } =
        useRegistrationHandler();
    const { t } = useTranslation();
    const registerDefaultValue = useRecoilValue(registrationAtom);

    const registrationFormSchema = useRegistrationFormSchema({
        email: sessionStorage.getItem("email") || "",
    });
    const navigate = useNavigate();
    const setuserRegistered = useSetRecoilState(userRegistered);
    useEffect(() => {
        if (isSucceded && user) {
            setuserRegistered(user);
            
            navigate(`/registration-confirm`);
        }
    }, [isSucceded, user]);

    const classes = useStyles();

    return (
        <>
            {!isSucceded && (
                <GenericForm
                    {...registrationFormSchema}
                    onSubmit={handleFormSubmit}
                    isLoading={isLoading}
                    className={`sform ${classes.form}`}
                    defaultValue={
                        registerDefaultValue || {
                            email: sessionStorage.getItem("email") || "",
                            organisation: "",
                            firstName: "",
                            lastName: "",
                            password: "",
                        }
                    }
                />
            )}
            {errorMessage && (
                <p className="serror text-center mt-20">
                    {t(`Login.${errorMessage}` || "")}
                    <Link to="/email">
                        {" "}
                        {t("Login.RegistationForm.RegisterLink.label")}
                    </Link>
                </p>
            )}
        </>
    );
};

export default RegistrationForm;
