import { useState } from 'react';
// import { useTranslation } from 'react-i18next';
import useSendRegistration, { TSendRegistrationResult } from './useSendRegistration';
import ReactGA from 'react-ga4';

export interface IUser {
    email?: string | any,
    firstName?: string,
    lastName?: string,
    organization?: string,
    password?: string,
}

interface IuseRegistration {
    data: IUser,
    isSucceded: boolean,
    handleFormSubmit: (value?: Record<string, any>) => void, 
    user: TSendRegistrationResult['user'],
    status?: number,
    errorMessage?: string,
    isLoading: boolean
}

const useRegistrationHandler = () : IuseRegistration =>  {
    const [data, setData] = useState<IUser>({});
    // const { t } = useTranslation()
    const {isSucceded, user, status, errorMessage, isLoading, setIsClicked} = useSendRegistration(data);
    
    const handleFormSubmit = (value?: Record<string, any>) => {
        value && setData(value);
        setIsClicked?.(true);
        sessionStorage.setItem('email', value?.email);
        ReactGA.event('sign_up', {method: 'Form'});
    };

    return {
        data,
        isSucceded,
        handleFormSubmit,
        user,
        status,
        errorMessage,
        isLoading
    }
}

export default useRegistrationHandler;
