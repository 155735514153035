import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '@eco/react-lib';
import { accountValidated } from 'state/atom';


import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { Button } from '@material-ui/core';

export const RegistrationValidated:React.FC = () => {

  const { t } = useTranslation();

  const [, setRegistrationValidated] = useGlobalState(accountValidated);

  useEffect(() => {
    // update global state user registration status.. used in login form
    setRegistrationValidated(true) ;
    ReactGA.event('signup_validated', {
      category: 'Event',
      action: 'Auth',
      label: 'Event/Register/RegistrationValidated',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) ;
  
  return(
      <>
        <div className="stext-center m-top-30">
          <p>
            {t('Login.Account.Validated.message')}
          </p>
          <p className="m-top-30">
            {t('Login.Account.Validated.Connection.message')}
          </p>
          <div className="m-top-50">
            <Link to="/email" style={{textDecoration:'none'}}>
              <Button
                  variant="contained"
                  id="continueOnAccountValidated">
                  {t('Login.Account.Validated.Continue.button')}
              </Button>
            </Link>
          </div>
        </div>
      </>
    )
}
